import { Mark } from "tiptap";
import { markInputRule, updateMark, removeMark } from "tiptap-commands";

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export default class FontSize extends Mark {
  get name() {
    return "fontSize";
  }

  get schema() {
    return {
      attrs: { fontSize: { default: "calc(1em + 0px)" } },
      parseDOM: [
        {
          style: "font-size",
          getAttrs: (value) => {
            return value.indexOf("em") !== -1 ? { fontSize: value } : "";
          },
        },
      ],
      toDOM: (mark) => [
        "span",
        { style: `font-size: ${mark.attrs.fontSize}` },
        0,
      ],
    };
  }

  commands({ type }) {
    return (attrs) => {
      return updateMark(type, attrs);
    };
  }

  inputRules({ type }) {
    return [markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)];
  }
}
