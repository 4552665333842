import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import { firestorePlugin } from "vuefire";
import { library } from "@fortawesome/fontawesome-svg-core";
import Editable from "@/components/Editable.vue";
import VueCookie from "vue-cookie";
import {
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteRight,
  faImage,
  faMinus,
  faUndo,
  faRedo,
  faSave,
  faAlignCenter,
  faAlignJustify,
  faAlignRight,
  faAlignLeft,
  faPlusSquare,
  faMinusSquare,
  faFont,
  faBorderAll,
  faPhoneAlt,
  faMobileAlt,
  faLink,
  faUnlink,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFacebookF,
  faXing,
  faWhatsapp,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

library.add({
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteRight,
  faImage,
  faMinus,
  faUndo,
  faRedo,
  faSave,
  faAlignCenter,
  faAlignJustify,
  faAlignRight,
  faAlignLeft,
  faPlusSquare,
  faMinusSquare,
  faFont,
  faBorderAll,
  faFacebookF,
  faXing,
  faWhatsapp,
  faLinkedinIn,
  faPhoneAlt,
  faMobileAlt,
  faLink,
  faUnlink,
  faPalette,
});
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("editable", Editable);

Vue.use(firestorePlugin);
Vue.use(VueAxios, axios);
Vue.use(VueCookie);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
