import Vue from "vue";
import Vuex from "vuex";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedIn: false,
    cookiesAccepted: false,
    dbData: null,
    editorOpen: "",
  },
  mutations: {
    isLoggedIn(state, bool) {
      state.loggedIn = bool;
    },
    isCookiesAccepted(state, bool) {
      state.cookiesAccepted = bool;
      if (state.cookiesAccepted) {
        Vue.use(VueReCaptcha, {
          siteKey: "6LfQbzoaAAAAABRFcVmruMCSUFNBB1WZoDqzYq7E",
        });
      }
    },
    updateDbData(state, data) {
      state.dbData = data;
    },
    changeEditorOpen(state, uid) {
      state.editorOpen = uid;
    },
  },
  actions: {},
  modules: {},
});
