<template>
  <div v-if="site">
    <div id="footer">
      <div class="footer-social-media-icons">
        <a
          :href="socialLink.facebook"
          target="_blank"
          class="footer-social-media-icon"
        >
          <font-awesome-icon :icon="['fab', 'facebook-f']" />
          <input
            v-model="socialLink.facebook"
            v-if="this.$store.state.loggedIn"
            @click.prevent
            type="text"
          />
        </a>
        <a
          :href="socialLink.xing"
          class="footer-social-media-icon"
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'xing']" />
          <input
            v-model="socialLink.xing"
            v-if="this.$store.state.loggedIn"
            @click.prevent
            type="text"
          />
        </a>
        <a
          :href="socialLink.whatsapp"
          class="footer-social-media-icon"
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'whatsapp']" />
          <input
            v-model="socialLink.whatsapp"
            v-if="this.$store.state.loggedIn"
            @click.prevent
            type="text"
          />
        </a>
        <a
          :href="socialLink.linkedin"
          class="footer-social-media-icon"
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'linkedin-in']" />
          <input
            v-model="socialLink.linkedin"
            v-if="this.$store.state.loggedIn"
            @click.prevent
            type="text"
          />
        </a>
        <div>
          <a
            :href="socialLink.phone"
            class="footer-social-media-icon"
          >
            <font-awesome-icon :icon="['fa', 'phone-alt']" />
            <input
              v-model="socialLink.phone"
              v-if="this.$store.state.loggedIn"
              @click.prevent
              type="text"
            />
          </a>
          <a
            :href="socialLink.mobilephone"
            class="footer-social-media-icon"
          >
            <font-awesome-icon :icon="['fa', 'mobile-alt']" />
            <input
              v-model="socialLink.mobilephone"
              v-if="this.$store.state.loggedIn"
              @click.prevent
              type="text"
            />
          </a>
        </div>

        <div
          style="position: absolute; margin-top: -35px; cursor: pointer"
          v-if="this.$store.state.loggedIn"
          @click="saveSocialLinks"
        >
          Save
        </div>
      </div>

      <div class="footer-menu">
        <router-link class="menu-item" :to="{ name: 'Home' }">
          <editable
            :document="$options.name"
            :dataEditId="`footer.main-menu.1`"
          ></editable>
        </router-link>

        <router-link
          class="menu-item"
          :to="{ name: 'Home', hash: '#leistungen' }"
        >
          <editable
            :document="$options.name"
            :dataEditId="`footer.main-menu.2`"
          ></editable>
        </router-link>

        <router-link
          class="menu-item"
          :to="{ name: 'Home', hash: '#vorstellen' }"
        >
          <editable
            :document="$options.name"
            :dataEditId="`footer.main-menu.3`"
          ></editable>
        </router-link>

        <router-link class="menu-item" :to="{ name: 'Home', hash: '#news' }">
          <editable
            :document="$options.name"
            :dataEditId="`footer.main-menu.4`"
          ></editable>
        </router-link>

        <router-link
          class="menu-item"
          :to="{ name: 'Home', hash: '#laufzeit' }"
        >
          <editable
            :document="$options.name"
            :dataEditId="`footer.main-menu.5`"
          ></editable>
        </router-link>

        <router-link class="menu-item" :to="{ name: 'Home', hash: '#kontakt' }">
          <editable
            :document="$options.name"
            :dataEditId="`footer.main-menu.6`"
          ></editable>
        </router-link>
      </div>

      <div class="footer-legal-information-links">
        <router-link class="menu-item" :to="{ name: 'Datenschutz' }">
          <editable
            :document="$options.name"
            :dataEditId="`footer.legal-menu.1`"
          ></editable>
        </router-link>
        <span>|</span>
        <router-link class="menu-item" :to="{ name: 'Impressum' }">
          <editable
            :document="$options.name"
            :dataEditId="`footer.legal-menu.2`"
          ></editable>
        </router-link>
      </div>
    </div>

    <div class="footer-bottom-extension">
      <div class="footer-slogan">
        <editable
          :document="$options.name"
          :dataEditId="`footer.motto`"
        ></editable>
      </div>
      <div class="footer-copyright">
        <editable
          :document="$options.name"
          :dataEditId="`footer.copyright`"
        ></editable>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db";

export default {
  props: ["index", "document", "site"],
  name: "App",
  data: () => ({
    socialLink: {
      facebook: "",
      xing: "",
      linkedin: "",
      whatsapp: "",
      phone: "",
      mobilephone: "",
    },
  }),
  methods: {
    saveSocialLinks() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};
        toUpdate[`footer.social-links`] = this.socialLink;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc("App")
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  mounted() {
    this.socialLink = this.site.App.footer["social-links"];
  },
};
</script>

<style scoped lang="scss">
@import "@/css/variables.scss";
@import "@/css/footer-section.scss";
</style>
