<template>
  <div id="header" :style="{
    backgroundImage: `url('${bgUrl}')`,
    //backgroundPositionX: `${bgPosition}%`,
  }">
    <div style="z-index: 1" v-if="this.$store.state.loggedIn">
      <p style="color: white">
        Here you can change the background image
        <br />and the background position.
      </p>
      <input v-model="bgUrl" type="text" placeholder="Background URL" />
      <button @click="saveBg">save</button>
      <br />
      <input v-model="bgPosition" type="number" placeholder="Background position in %" />
    </div>
    <div class="header-content">
      <div class="header-name">
        <editable document="App" :dataEditId="`header.pre-headline`"></editable>
      </div>
      <div class="header-main">
        <editable document="App" :dataEditId="`header.headline`"></editable>
      </div>
      <div class="header-main-caption">
        <editable document="App" style="position: absolute; margin: 0 auto; bottom: 23%; width: 100%; right: 30px"
          :dataEditId="`header.post-headline`"></editable>
      </div>

      <div class="header-buttons" style="position: absolute; margin: 0 auto; bottom: 3%">
        <router-link class="header-outline-button" :to="{ name: 'Home', hash: '#kontakt' }">
          <editable document="App" :dataEditId="`header.kontakt-button`"></editable>
        </router-link>
        <router-link class="header-no-outline-button" :to="{ name: 'Home', hash: '#menu' }">
          <editable document="App" :dataEditId="`header.mehr-informationen`"></editable>
          <span></span>
        </router-link>
      </div>
      <!-- <router-link class="header-arrows" :to="{ name: 'Home', hash: '#menu' }">
        <span style="top: 5px" class="header-arrow">
          <span></span>
          <span></span>
        </span>
        <span style="top: 10px" class="header-arrow">
          <span></span>
          <span></span>
        </span>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import { db } from "@/db";

export default {
  props: ["index", "document", "site"],
  name: "Home",
  data: () => ({
    bgUrl: "",
    bgPosition: null,
  }),
  methods: {
    saveBg() {
      if (this.$store.state.loggedIn) {
        let toUpdate = {};

        toUpdate[`header.background-url`] = this.bgUrl;
        toUpdate[`header.background-position`] = this.bgPosition;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc("App")
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  mounted() {
    this.bgUrl = this.site.App.header["background-url"];
    this.bgPosition = this.site.App.header["background-position"];
  },
};
</script>

<style scoped lang="scss">
@import "@/css/variables.scss";
@import "@/css/header-section.scss";
</style>
