<template>
  <div id="menu" :class="{ 'menu-open': menu }">
    <div>
      <img class="menu-logo" src="/img/logo-white.svg" />
      <!--<div class="language-select">
        <p
          :class="{ 'selected-language': selectedLanguage === 'en' }"
          @click="setLanguageCookie"
          data-lang="en"
        >
          en
        </p>
        <span>/</span>
        <p
          :class="{
            'selected-language':
              selectedLanguage === 'de' || selectedLanguage == null,
          }"
          @click="setLanguageCookie"
          data-lang="de"
        >
          de
        </p>
      </div>-->
    </div>
    <p v-if="this.$store.state.loggedIn">
      ⚠️ You are currently in editing mode ⚠️
    </p>
    <div class="menu-open-close" @click="toggleMenu">
      <span></span>
      <a></a>
    </div>
    <div class="menu-item-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["index", "document", "site", "cookiesAccepted"],
  name: "Home",
  data: () => ({
    selectedLanguage: null,
    menu: false,
  }),
  methods: {
    setLanguageCookie(event) {
      let e = event.target,
        val = e.getAttribute("data-lang");

      this.$cookie.set("language", val, 1000);
      this.$router.go();
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
  },
  mounted() {
    this.selectedLanguage = this.$cookie.get("language");
  },
};
</script>

<style scoped lang="scss">
@import "@/css/variables.scss";
@import "@/css/menu-section.scss";
</style>
