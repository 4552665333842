import { Mark } from "tiptap";
import { markInputRule, updateMark, removeMark } from "tiptap-commands";

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export default class TextColor extends Mark {
  get name() {
    return "textColor";
  }

  get schema() {
    return {
      attrs: { color: { default: "#807876" } },
      parseDOM: [
        {
          style: "color",
          getAttrs: (value) => {
            return value.indexOf("#") !== -1 ? { color: value } : "";
          },
        },
      ],
      toDOM: (mark) => ["span", { style: `color: ${mark.attrs.color}` }, 0],
    };
  }

  commands({ type }) {
    return (attrs) => {
      return updateMark(type, attrs);
    };
  }

  inputRules({ type }) {
    return [markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)];
  }
}
