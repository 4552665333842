<template>
  <div ref="md" :style="{
    outline: this.$store.state.loggedIn ? '2px solid #039be5' : '0px',
  }">
    <div class="editor">
      <div v-show="show" v-if="this.$store.state.loggedIn">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
          <Moveable v-bind="moveable" class="menubar moveable" @drag="handleDrag">
            <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
              <font-awesome-icon :icon="['fa', 'bold']" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
              <font-awesome-icon :icon="['fa', 'italic']" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
              <font-awesome-icon :icon="['fa', 'strikethrough']" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
              <font-awesome-icon :icon="['fa', 'underline']" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
              <font-awesome-icon :icon="['fa', 'paragraph']" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })">
              H1
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })">
              H2
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })">
              H3
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list">
              <font-awesome-icon :icon="['fa', 'list-ul']" />
            </button>

            <button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list">
              <font-awesome-icon :icon="['fa', 'list-ol']" />
            </button>

            <button class="menubar__button" @click="showImagePrompt(commands.image)">
              <font-awesome-icon :icon="['fa', 'image']" />
            </button>

            <button class="menubar__button" @click="commands.horizontal_rule">
              <font-awesome-icon :icon="['fa', 'minus']" />
            </button>

            <button class="menubar__button" @click="commands.undo">
              <font-awesome-icon :icon="['fa', 'undo']" />
            </button>

            <button class="menubar__button" @click="commands.redo">
              <font-awesome-icon :icon="['fa', 'redo']" />
            </button>

            <button class="menubar__button" @click="
              commands.createTable({
                rowsCount: 3,
                colsCount: 3,
                withHeaderRow: false,
              })
              " :class="{
    'is-active': isActive.table(),
  }">
              <font-awesome-icon :icon="['fa', 'border-all']" />
            </button>

            <span class="editor-menu vertical-editor-menu" v-if="isActive.table()">
              <button class="menubar__button" @click="commands.deleteTable">
                Delete table
              </button>
              <button class="menubar__button" @click="commands.addColumnBefore">
                Add column before
              </button>
              <button class="menubar__button" @click="commands.addColumnAfter">
                Add column after
              </button>
              <button class="menubar__button" @click="commands.deleteColumn">
                Delete column
              </button>
              <button class="menubar__button" @click="commands.addRowBefore">
                Add row before
              </button>
              <button class="menubar__button" @click="commands.addRowAfter">
                Add row after
              </button>
              <button class="menubar__button" @click="commands.deleteRow">
                Delete row
              </button>
              <button class="menubar__button" @click="commands.toggleCellMerge">
                Cell merge
              </button>
            </span>

            <button :class="{
              'is-active': getMarkAttrs('alignment').textAlign === 'left',
            }" class="menubar__button" @click="commands.alignment({ textAlign: 'left' })">
              <font-awesome-icon :icon="['fa', 'align-left']" />
            </button>

            <button :class="{
              'is-active': getMarkAttrs('alignment').textAlign === 'center',
            }" class="menubar__button" @click="commands.alignment({ textAlign: 'center' })">
              <font-awesome-icon :icon="['fa', 'align-center']" />
            </button>

            <button :class="{
              'is-active': getMarkAttrs('alignment').textAlign === 'right',
            }" class="menubar__button" @click="commands.alignment({ textAlign: 'right' })">
              <font-awesome-icon :icon="['fa', 'align-right']" />
            </button>

            <button :class="{
              'is-active': getMarkAttrs('alignment').textAlign === 'justify',
            }" class="menubar__button" @click="commands.alignment({ textAlign: 'justify' })">
              <font-awesome-icon :icon="['fa', 'align-justify']" />
            </button>

            <button :class="{
              'is-active': openFontFamilyMenu,
            }" class="menubar__button" @click="openFontFamilyMenu = !openFontFamilyMenu">
              <font-awesome-icon :icon="['fa', 'font']" />
            </button>

            <span class="editor-menu vertical-editor-menu" v-if="openFontFamilyMenu">
              <button :class="{
                'is-active':
                  getMarkAttrs('fontFamily').fontFamily === 'Quicksand',
              }" class="menubar__button" @click="commands.fontFamily({ fontFamily: 'Quicksand' })">
                Quicksand
              </button>

              <button :class="{
                'is-active':
                  getMarkAttrs('fontFamily').fontFamily === 'Open Sans',
              }" class="menubar__button" @click="commands.fontFamily({ fontFamily: 'Open Sans' })">
                Open Sans
              </button>

              <button :class="{
                'is-active':
                  getMarkAttrs('fontFamily').fontFamily === 'Times New Roman',
              }" class="menubar__button" @click="commands.fontFamily({ fontFamily: 'Times New Roman' })">
                Times New Roman
              </button>

              <button :class="{
                'is-active':
                  getMarkAttrs('fontFamily').fontFamily === 'Arial',
              }" class="menubar__button" @click="commands.fontFamily({ fontFamily: 'Arial' })">
                Arial
              </button>
            </span>

            <button class="menubar__button" @click="
              commands.fontSize({
                fontSize:
                  'calc(1em + ' +
                  (parseInt(
                    (
                      getMarkAttrs('fontSize').fontSize || 'calc(1em + 0px)'
                    ).substring(10)
                  ) +
                    1) +
                  'px)',
              })
              ">
              <font-awesome-icon :icon="['fa', 'plus-square']" />
            </button>

            <button class="menubar__button">
              {{
                parseInt(
                  (
                    getMarkAttrs("fontSize").fontSize || "calc(1em + 0px)"
                  ).substring(10)
                )
              }}
            </button>

            <button class="menubar__button" @click="
            commands.fontSize({
              fontSize:
                'calc(1em + ' +
                (parseInt(
                  (
                    getMarkAttrs('fontSize').fontSize || 'calc(1em + 0px)'
                  ).substring(10)
                ) -
                  1) +
                'px)',
            })
              ">
              <font-awesome-icon :icon="['fa', 'minus-square']" />
            </button>

            <div>
              <input class="menubar__button" @change="commands.textColor({ color: colorPicker })" type="color"
                v-model="colorPicker" :id="_uid" style="display: none" />
              <label class="menubar__button" style="align-items: center" :for="_uid">
                <font-awesome-icon :style="{ color: getMarkAttrs('textColor').color }" :icon="['fa', 'palette']" />
              </label>
            </div>

            <form style="display: flex" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
              <input type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu" />
              <button class="menubar__button" @click.prevent="setLinkUrl(commands.link, linkUrl)" type="button">
                <font-awesome-icon :icon="['fa', 'save']" />
              </button>
              <button class="menubar__button" @click="setLinkUrl(commands.link, null)" type="button">
                <font-awesome-icon :icon="['fa', 'unlink']" />
              </button>
            </form>

            <div v-else>
              <button class="menubar__button" @click="showLinkMenu(getMarkAttrs('link'))"
                :class="{ 'is-active': isActive.link() }">
                <span>
                  <font-awesome-icon :icon="['fa', 'link']" />
                </span>
              </button>
            </div>

            <button @click="
              commands.fontSize({ fontSize: 'calc(1em + 0px)' });
            colorPicker = 'inherit';
            commands.textColor({ color: colorPicker });
            " class="menubar__button">
              Reset
            </button>

            <button class="menubar__button" @click="save">
              <font-awesome-icon :icon="['fa', 'save']" />
            </button>
          </Moveable>
        </editor-menu-bar>
      </div>
      <div @click="focus">
        <editor-content style="min-width: 5px" class="editor__content" :editor="editor"></editor-content>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import Moveable from "vue-moveable";
import {
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Strike,
  Underline,
  History,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from "tiptap-extensions";

import Alignment from "@/tiptap-extensions/Alignment";
import FontFamily from "@/tiptap-extensions/FontFamily";
import FontSize from "@/tiptap-extensions/FontSize";
import TextColor from "@/tiptap-extensions/TextColor";
import Link from "@/tiptap-extensions/Link";

export default {
  props: ["dataEditId", "document"],
  components: {
    EditorContent,
    EditorMenuBar,
    Moveable,
  },
  data: () => ({
    show: false,
    text: "",
    colorPicker: "#000000",
    openFontFamilyMenu: false,
    linkUrl: null,
    linkMenuIsActive: false,
    unsubscribe: null,
    source: "",
    moveable: {
      draggable: true,
      throttleDrag: 10,
    },
    editor: new Editor({
      extensions: [
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        new Image(),
        new Table({
          resizable: true,
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Alignment(),
        new FontFamily(),
        new FontSize(),
        new TextColor(),
      ],
      content: "",
      editable: false,
    }),
  }),
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
    focus() {
      if (this.$store.state.loggedIn) {
        this.show = true;
        this.editor.focus();
      }

      this.$store.commit("changeEditorOpen", this._uid);
    },
    handleDrag({ target, transform }) {
      target.style.transform = transform;
    },
    save() {
      let updateText = this.editor.getHTML();

      if (this.$store.state.loggedIn && this.source !== updateText) {
        let toUpdate = {};
        toUpdate[this.dataEditId] = updateText;

        let dbName = "sites-en";
        if (this.$cookie.get("language") === "de") {
          dbName = "sites";
        }

        db.collection(dbName)
          .doc(this.document)
          .update(toUpdate)
          .catch((error) => {
            console.error(error);
          });
      }

      this.show = false;
    },
    showImagePrompt(command) {
      const src = prompt("Enter the url of your image here");
      if (src !== null) {
        command({ src });
      }
    },
  },
  watch: {
    source: {
      immediate: true,
      handler() {
        if (this.$store.state.loggedIn) {
          this.text = this.source;
        } else {
          this.text = this.source
            .split("&lt;")
            .join("<")
            .split("&gt;")
            .join(">");
        }

        this.editor.setContent(this.text);
      },
    },
    "$store.state.dbData": function (change) {
      let val = change[this.document];

      this.dataEditId.split(".").forEach((value) => {
        val = val[value];
      });
      this.source = val;
    },
    loggedIn: {
      immediate: true,
      handler() {
        if (!this.loggedIn) this.emptyText = "";
      },
    },
  },
  created() {
    if (this.$store.state.loggedIn) {
      this.editor.setOptions({
        editable: true,
      });

      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (
          mutation.type === "changeEditorOpen" &&
          this._uid !== state.editorOpen &&
          this.show == true
        ) {
          this.save();
        }
      });
    }

    var source = this.$store.state.dbData[this.document];
    this.dataEditId.split(".").forEach((elem) => {
      source = source[elem];
    });

    this.source = source;
  },
  beforeDestroy() {
    //this.unsubscribe();
  },
};
</script>

<style lang="scss">
.ProseMirror-separator {
  display: none;
}

.editor {
  img+br {
    display: none;
  }

  .editor-menu {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .vertical-editor-menu {
    flex-flow: column;

    .menubar__button {
      width: initial;
      height: initial;
      border-radius: 5px;
    }
  }

  .editor__content>div {
    outline: 0;
  }

  .menubar {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    background: #ffeb3b;
    justify-content: space-around;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 9999999;
    width: 100%;
  }

  .menubar__button {
    background: transparent;
    border: 0;
    color: #000;
    padding: 0.5rem;
    margin: 0.1rem;
    border-radius: 3px;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    outline: 0;

    &.is-active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.editor__content {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ddd;
      padding: 3px 5px;
      vertical-align: top;
      position: relative;

      * {
        margin-bottom: 0;
      }
    }
  }
}
</style>
