<template>
  <div id="app" v-if="this.$store.state.dbData">
    <div v-if="!cookieClose && !!this.$store.state.dbData.App" id="cookie-disclaimer">
      <button @click="
  $store.commit('isCookiesAccepted', false);
cookieClose = true;
      " id="cookies-close">
        <span></span>
        <span></span>
      </button>
      <div>
        <div>
          <!--<img src="/img/leistungen_icons/cookie.png" alt="" />-->
          <h1>Cookies!</h1>
          <p id="cookie-text">
            <editable :document="$options.name" :dataEditId="`cookies.text`"></editable>
          </p>
          <button @click="
  $store.commit('isCookiesAccepted', true);
$cookie.set('cookie', true, 1000);
$cookie.set('language', 'de', 1000);
cookieClose = true;
          " id="cookies-accept">
            <editable :document="$options.name" :dataEditId="`cookies.button-text`"></editable>
          </button>
        </div>
      </div>
    </div>

    <div v-if="!this.$store.state.dbData.App" id="loading-screen">
      <div>
        <img id="img-c-letter" src="/img/logo-c-letter.svg" />
        <img id="img-first-ring" src="/img/logo-first-ring.svg" />
        <img id="img-second-ring" src="/img/logo-second-ring.svg" />
        <img id="img-third-ring" src="/img/logo-third-ring.svg" />
      </div>
    </div>

    <Header :site="this.$store.state.dbData" v-if="this.$store.state.dbData.App">
      <p class="header-pre-caption">
        <editable :document="$options.name" :dataEditId="`header.pre-headline`"></editable>
      </p>
      <h1 class="header-title">
        <editable :document="$options.name" :dataEditId="`header.headline`"></editable>
      </h1>
      <p class="header-caption">
        <editable :document="$options.name" :dataEditId="`header.post-headline`"></editable>
      </p>
    </Header>

    <Menu v-if="this.$store.state.dbData.App" style="position: sticky">
      <router-link class="menu-item" :to="{ name: 'Home' }">
        <editable :document="$options.name" :dataEditId="`main-menu.1`"></editable>
      </router-link>

      <router-link v-if="this.$store.state.dbData.Home['leistungen-section'].display" class="menu-item"
        :to="{ name: 'Home', hash: '#leistungen' }">
        <editable :document="$options.name" :dataEditId="`main-menu.2`"></editable>
      </router-link>

      <router-link v-if="this.$store.state.dbData.Home['vorstellen-section'].display" mousedown="location.hash = '#';"
        class="menu-item" :to="{ name: 'Home', hash: '#vorstellen' }">
        <editable :document="$options.name" :dataEditId="`main-menu.3`"></editable>
      </router-link>

      <router-link v-if="this.$store.state.dbData.Home['news-section'].display" class="menu-item"
        :to="{ name: 'Home', hash: '#news' }">
        <editable :document="$options.name" :dataEditId="`main-menu.6`"></editable>
      </router-link>

      <router-link v-if="this.$store.state.dbData.Home['laufzeit-section'].display" class="menu-item"
        :to="{ name: 'Home', hash: '#laufzeit' }">
        <editable :document="$options.name" :dataEditId="`main-menu.4`"></editable>
      </router-link>

      <router-link v-if="this.$store.state.dbData.Home['kontakt-section'].display" class="menu-item"
        :to="{ name: 'Home', hash: '#kontakt' }">
        <editable :document="$options.name" :dataEditId="`main-menu.5`"></editable>
      </router-link>

      <p v-if="this.$store.state.loggedIn" class="menu-item" @click="logout()">
        Logout
      </p>
    </Menu>

    <keep-alive>
      <router-view v-if="this.$store.state.dbData.App" :computedDbData="this.$store.state.dbData"></router-view>
    </keep-alive>

    <footer v-if="this.$store.state.dbData.App">
      <Footer :site="this.$store.state.dbData"></Footer>
    </footer>
  </div>
</template>

<script>
import { db } from "@/db";
import firebase from "firebase/app";
import "firebase/auth";
import Menu from "@/components/Menu.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Menu,
    Header,
    Footer,
  },
  data: () => ({
    rawDbData: null,
    cookieClose: false,
  }),
  firestore() {
    let dbName = "sites";
    if (this.$cookie.get("language") === "en") {
      dbName = "sites-en";
    }

    return {
      rawDbData: db.collection(dbName),
    };
  },
  watch: {
    rawDbData: function () {
      let computedDbData = {};
      try {
        this.rawDbData.forEach((doc) => {
          computedDbData[doc.id] = doc;
        });
      } catch { }

      this.$store.commit("updateDbData", computedDbData);
    },
  },
  methods: {
    logout: function () {
      var self = this;
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            this.$store.commit("isLoggedIn", false);
          },
          (error) => {
            console.error(error);
          }
        );
      location.reload();
    },
  },
  created() {
    var self = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.commit("isLoggedIn", true);
      }
    });
  },

  mounted() {
    let cookie = this.$cookie.get("cookie");

    if (cookie) {
      this.$store.commit("isCookiesAccepted", true);
      this.cookieClose = true;
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;600;700&display=swap");
@import "@/css/variables.scss";

.grecaptcha-badge {
  z-index: 101;
}

img {
  width: 100%;
}

* {
  box-sizing: border-box;
}

span,
p,
a,
div,
textarea,
input,
button,
table {
  font-family: "Quicksand", "Open Sans", sans-serif;
}

ol,
li {
  font-size: 17px;
}

img {
  display: block;
}

hr {
  padding: 10px 0;
  border: 0;
  margin: 0;

  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: #9a9a9a;
  }
}

p {
  margin: 0;
  font-size: 17px;
}

p:empty {
  margin: 1em;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 18px;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section-headline {
  text-transform: uppercase;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  margin-top: 0;
}

#loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  flex-flow: column;

  >div {
    position: relative;
    width: 100px;
    height: 100px;
  }

  img,
  svg {
    width: auto;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotating-back {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(-360deg);
    }
  }

  #img-first-ring {
    animation: rotating 1.5s linear infinite;
  }

  #img-second-ring {
    animation: rotating-back 1.5s linear infinite;
  }

  #img-third-ring {
    animation: rotating 2s linear infinite;
  }

  #img-c-letter,
  svg {
    animation: rotating-back 2.5s linear infinite;
  }
}

#cookies-accept {
  p {
    font-size: 14px !important;
  }
}

.colored-bar span {
  display: block;
}

table {
  min-width: initial !important;

  colgroup {
    col {
      width: initial !important;
    }
  }
}
</style>

<style lang="scss" scoped>
#logged-in-notification-bar {
  background: #039be5;
  width: 100%;
  height: 50px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: sticky;
  z-index: 100000;
  top: 0;
}

#logout {
  position: absolute;
  right: 25px;
  cursor: pointer;
  margin: 0;
}

#cookie-disclaimer {
  position: fixed;
  bottom: 50px;
  z-index: 1000;
  left: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.3);

  #cookies-accept {
    height: 40px;
    border-radius: 100px;
    background: #132746;
    border: 2px solid #132746;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 11px;
    padding: 0 35px;
    transition: all 0.15s ease-out;
    cursor: pointer;
    outline: none;

    &:hover {
      background: white;
      color: #132746;
    }

    p {
      font-size: 14px !important;
    }
  }

  #cookies-close {
    background: transparent;
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 200;
    outline: 0;

    &:hover>span {
      background: #bbbbbb;
    }

    >span {
      background: #d2d2d2;
      display: block;
      height: 3px;
      width: 15px;
      border-radius: 100px;
      left: 10px;
      transition: all 0.15s ease-out;

      &:first-child {
        transform: rotate(45deg);
        position: relative;
        top: 2px;
      }

      &:last-child {
        position: relative;
        transform: rotate(-45deg);
        bottom: 1px;
      }
    }
  }

  img {
    margin: 0 auto;
    width: 100px;
    margin-bottom: 10px;
  }

  h1 {
    margin: 0;
  }

  >div {
    padding: 15px;
    position: relative;

    >div {
      padding: 30px;

      #cookie-text {
        font-size: 14px;
        line-height: 23px;
        margin: 25px 0;
      }
    }
  }
}

@media only screen and (max-width: 625px) {
  #cookie-disclaimer {
    width: 80%;
    margin: 0 auto;
    left: 10%;
    top: 50px;
  }
}

@media only screen and (max-height: 520px) {
  #cookie-disclaimer img {
    display: none;
  }
}
</style>
